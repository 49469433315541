import '../../../assets/styles/home/WordsFromCrew.css';
import Carousel2 from '../../../components/Carousels/Carousel2';
import wordsFromCrew from '../../../constants/wordsFromCrew';
import Carousel from '../../Carousels/Carousel';

export default function WordsFromCrew() {
    return (
        <section className="words-from-crew-section" id="words-from-crew-section">
            <div className="glow sixth"></div>
            <div className="words-from-crew-section-header">Word from the Crew</div>
            <Carousel crewList={wordsFromCrew}></Carousel>
        </section>
    )
}