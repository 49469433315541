import Carousel from "../Carousels/Carousel1";
import OverlayCarousel from "../Overlay/OverlayCarousel";
import LandingPageButton from "../Buttons/LandingPageButton";
import "../../components/Buttons/LandingPageButton";
import { useEffect, useState } from "react";
import contacts from "../../constants/contacts";

export default function Hero() {
  const [contactsCollection, setContactsCollection] = useState([]);

  useEffect(() => {
    setContactsCollection((prevContactsCollection) => {
      return contacts.map((contact) => {
        return (
          <a href={contact.url} target="_blank" key={Math.random() * 10000}>
            <img src={contact.aboutSectionIcon} alt={`${contact.name} Icon`} />
          </a>
        );
      });
    });
  }, []);

  return (
    <section className="hero-section" id="about-section">
      <Carousel />
      <div className="overlay" style={{ pointerEvents: "none" }}></div>
      <div className="hero-section-caption">
        <div className="hero-section-bg"></div>
        <div className="outplayed-title">OUTPLAYED.in</div>
        <p>
          We are here to make eSports a viable career option. Join our platform,
          build the community & become the eSports player you always wanted to
          be.
        </p>
        {/* <LandingPageButton text={"Register for Beta"} /> */}
        <button
          style={{
            background: "none",
            border: "none",
            display: "flex",
            justifyContent: "start",
          }}
          onClick={() => {
            window.open(
              "https://play.google.com/store/apps/details?id=com.outplayed",
              "_blank"
            );
          }}
        >
          <img
            src="/assets/images/googlePlayLogo.png"
            alt="Outplayed Android App Link"
            className="get-it-on-google-play"
          />
        </button>
      </div>
      <div className="hero-section__contacts">{contactsCollection}</div>
    </section>
  );
}
