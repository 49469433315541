import React from "react";
import "../assets/styles/PrivacyPolicy.css";
import { termsOfServiceContent } from "../constants/termsAndConditionsConfig";

export default function ContactUs() {
  return (
    <div>
      <div className="privacy-header">
        <img src="/assets/images/features/OutplayedLogo.svg" />
        <h2>Contact Us</h2>
      </div>
      <div className="privacy-shipping">
        <div className="privacy-body-title">
          <p>You can contact us at: </p>
        </div>
        <div className="privacy-body-content">
          <p>Outplayed.in, owned by Fraemerate Private Limited</p>
          <p>
            193, No. 2 Brahmin Gaon, Kenduguri, Jorhat, Pub-Charigaon,
            Jorhat-785010, Assam, India
          </p>
          <p>
            <a href="mailto:support@outplayed.in">support@outplayed.in</a>
          </p>
          <p>+91 8638163867</p>
        </div>
        <div className="privacy-copyright">
          <p>2025 &copy; Outplayed.in (owned by Fraemerate Pvt. Ltd.)</p>
        </div>
      </div>
    </div>
  );
}
