import React from "react";
import { privacyPolicyContent } from "../../constants/privacyPolicyConfig";
import "../../assets/styles/components/versionPrivacyStyles/corporateVersion.css";

export default function CorporateVersionPrivacy() {
  return (
    <div>
      {/* Privacy Policy Details */}
      <div>
        {Object.keys(privacyPolicyContent.privacyPolicy.details).map(
          (key, index) => (
            <div key={index} className="details">
              <strong>{key}&ensp;:&ensp;</strong>
              <span>{privacyPolicyContent.privacyPolicy.details[key]}</span>
            </div>
          )
        )}
      </div>

      {/* Terms and Conditions */}
      <div className="terms-and-conditions">
        <strong>Terms and Conditions</strong>
        <div>
          {Object.keys(privacyPolicyContent.termsAndConditions).map(
            (key, index) => (
              <div key={index} className="term-condition-body">
                <strong>{key}&ensp;:&ensp;</strong>
                <span>{privacyPolicyContent.termsAndConditions[key]}</span>
              </div>
            )
          )}
        </div>
      </div>

      {/* Cancellation & Refund Policy */}
      <div className="cancellation-policy">
        <strong>Cancellation & Refund Policy</strong>
        <strong>FOR PLAYERS & TEAMS</strong>
        <div>
          {Object.keys(privacyPolicyContent.cancellationAndRefundPolicyPlayers).map(
            (key, index) => (
              <div key={index} className="cancellation-policy-body">
                <strong>{key}&ensp;:&ensp;</strong>
                <span>{privacyPolicyContent.cancellationAndRefundPolicyPlayers[key]}</span>
              </div>
            )
          )}
        </div>
        <strong style={{ marginTop: "20px" }}>FOR ORGANISERS</strong>
        <div>
          {Object.keys(privacyPolicyContent.cancellationAndRefundPolicyOrganisers).map(
            (key, index) => (
              <div key={index} className="cancellation-policy-body">
                <strong>{key}&ensp;:&ensp;</strong>
                <span>{privacyPolicyContent.cancellationAndRefundPolicyOrganisers[key]}</span>
              </div>
            )
          )}
        </div>
      </div>

      {/* Shipping Policy */}
      <div className="terms-and-conditions">
        <strong>Shipping Policy</strong>
        <div>
          {Object.keys(privacyPolicyContent.shippingPolicy).map(
            (key, index) => (
              <div key={index} className="term-condition-body">
                <strong>{privacyPolicyContent.shippingPolicy[key]}</strong>
              </div>
            )
          )}
        </div>
      </div>

      {/* Policy Updates */}
      <div className="privacy-updates">
        <p>
          <strong>Update in Privacy Policy and Terms&ensp;:&ensp;</strong>
          <strong>{privacyPolicyContent.policyUpdates[3]}</strong>
        </p>
      </div>

      {/* Governing Law */}
      <div className="privacy-governingLaw">
        <p>
          <strong>Governing Law&ensp;:&ensp;</strong>
          <strong>{privacyPolicyContent.governingLaw[4]}</strong>
        </p>
      </div>

      {/* Grievances and Withdrawal of Consent */}
      <div className="privacy-grievances">
        <p>
          <strong>Grievances and Withdrawal of Consent&ensp;:&ensp;</strong>
          <strong>{privacyPolicyContent.grievances[5].contact}</strong>
        </p>
        <p>
          {Object.keys(privacyPolicyContent.grievances[5].fields).map((key) => (
            <p>
              <strong>{key}&ensp;:&ensp;</strong>
              <strong>{privacyPolicyContent.grievances[5].fields[key]}</strong>
            </p>
          ))}
        </p>
        <p>
          <strong>{privacyPolicyContent.grievances[5].withdrawal}</strong>
        </p>
      </div>
      {/* Disclaimer */}
      <div className="privacy-disclaimer">
        <p>
          <strong>Disclaimer&ensp;:&ensp;</strong>
          <strong>{privacyPolicyContent.disclaimer[6]}</strong>
        </p>
      </div>
    </div>
  );
}
