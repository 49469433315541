import React from "react";
import { privacyPolicyContent } from "../constants/privacyPolicyConfig";
import CorporateVersionPrivacy from "../components/VersionPrivacy/CorporateVersionPrivacy";
import "../assets/styles/PrivacyPolicy.css";
import { cancellationAndRefundContent } from "../constants/cancellationAndRefundConfig";
import { termsOfServiceContent } from "../constants/termsAndConditionsConfig";

export default function TermsAndConditions() {
  return (
    <div>
      <div className="privacy-header">
        <img src="/assets/images/features/OutplayedLogo.svg" />
        <h2>Terms and Conditions</h2>
      </div>
      <div className="privacy-body">
        <p>
          <strong>
            <i>Last modified: February 19, 2025</i>
          </strong>
        </p>
        <div className="privacy-body-content">
          <p>
            <strong>{termsOfServiceContent.header}</strong>
          </p>
        </div>
        <div className="privacy-body-title">
          <p>Account Rules</p>
        </div>
        {Object.entries(termsOfServiceContent.accountRules).map(
          ([key, value], index) => {
            return (
              <div className="privacy-body-content">
                <p>{value}</p>
              </div>
            );
          }
        )}
        <div className="privacy-body-title">
          <p>Age Requirements</p>
        </div>
        {Object.entries(termsOfServiceContent.ageRequirements).map(
          ([key, value]) => {
            return (
              <div className="privacy-body-content">
                <p>{value}</p>
              </div>
            );
          }
        )}
        <div className="privacy-body-title">
          <p>Esports Tournaments</p>
        </div>
        {Object.entries(termsOfServiceContent.esportsTournaments).map(
          ([key, value]) => {
            return (
              <div className="privacy-body-content">
                <p>{value}</p>
              </div>
            );
          }
        )}
        <div className="privacy-body-title">
          <p>Streaming</p>
        </div>
        {Object.entries(termsOfServiceContent.streaming).map(
          ([key, value]) => {
            return (
              <div className="privacy-body-content">
                <p>{value}</p>
              </div>
            );
          }
        )}
        <div className="privacy-body-title">
          <p>Content Ownership</p>
        </div>
        {Object.entries(termsOfServiceContent.contentOwnership).map(
          ([key, value]) => {
            return (
              <div className="privacy-body-content">
                <p>{value}</p>
              </div>
            );
          }
        )}
        <div className="privacy-body-title">
          <p>Payments</p>
        </div>
        {Object.entries(termsOfServiceContent.payments).map(([key, value]) => {
          return (
            <div className="privacy-body-content">
              <p>{value}</p>
            </div>
          );
        })}
        <div className="privacy-body-title">
          <p>Platform Use</p>
        </div>
        {Object.entries(termsOfServiceContent.platformUse).map(
          ([key, value]) => {
            return (
              <div className="privacy-body-content">
                <p>{value}</p>
              </div>
            );
          }
        )}
        <div className="privacy-body-title">
          <p>Liability</p>
        </div>
        {Object.entries(termsOfServiceContent.liability).map(
          ([key, value]) => {
            return (
              <div className="privacy-body-content">
                <p>{value}</p>
              </div>
            );
          }
        )}
        <div className="privacy-body-title">
          <p>No Betting or Gambling Policy</p>
        </div>
        {Object.entries(termsOfServiceContent.noGamblingOrBetting).map(
          ([key, value]) => {
            return (
              <div className="privacy-body-content">
                <p>{value}</p>
              </div>
            );
          }
        )}
        <div className="privacy-body-title">
          <p>Change of Terms</p>
        </div>
        <div className="privacy-body-content">
          <p>{termsOfServiceContent.changes}</p>
        </div>
        <div className="privacy-copyright">
          <p>2025 &copy; Outplayed.in (owned by Fraemerate Pvt. Ltd.)</p>
        </div>
      </div>
    </div>
  );
}
