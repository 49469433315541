import axios from "axios";
import { getToken } from "../utils/tokenfunction";

/**
 * Global Axios Call Function
 * @param {string} baseURL - The base URL of the API.
 * @param {string} url - The endpoint to call.
 * @param {string} method - HTTP method (GET, POST, PUT, DELETE, etc.).
 * @param {Object} [data={}] - Request body (for POST, PUT, etc.).
 * @param {Object} [headers={}] - Additional headers.
 * @param {string} [token=null] - Authorization token.
 * @returns {Promise<Object>} - Response data or error object.
 */
const globalAxiosCall = async ({
  baseURL,
  url,
  method,
  data = {},
  headers = {},
  token = null,
}) => {
  try {
    const axiosInstance = axios.create({
      baseURL,
      timeout: 10000, // 10 seconds timeout
    });

    const AccessToken = getToken("AccessToken");
    if (token) {
      headers["Authorization"] = `Bearer ${AccessToken}`;
    }

    // Ensure Content-Type is set to application/json if not already specified
    if (!headers["Content-Type"]) {
      headers["Content-Type"] = "application/json";
    }

    const response = await axiosInstance({
      url,
      method,
      data,
      headers,
    });
    return { success: true, data: response.data };
  } catch (error) {
    // Pass errors to global error handler
    throw handleGlobalError(error);
  }
};

/**
 * Global Error Handler
 * This function will centralize error processing.
 * @param {Object} error - Axios error object.
 * @returns {Object} - Processed error information.
 */
const handleGlobalError = (error) => {
  if (error.response) {
    // Server responded with a status code out of the 2xx range
    const status = error.response.status;
    const errorMessages = {
      400: "Bad Request - Please check the input and try again.",
      401: "Unauthorized - Please log in again.",
      403: "Forbidden - You do not have permission to perform this action.",
      404: "Resource not found - The requested item could not be found.",
      500: "Internal Server Error - Something went wrong on our end.",
      503: "Service Unavailable - Please try again later.",
    };
    if (error.response.data?.category === "APP_ERROR")
      return {
        success: false,
        details: error.response.data || {},
      };
    else if (error.response.data?.category === "VALIDATION_ERROR")
      return {
        success: false,
        details: error.response.data || {},
      };
    else
      return {
        success: false,
        status,
        message: errorMessages[status] || "An error occurred on the server.",
        details: error.response.data || {},
      };
  } else if (error.request) {
    // No response from the server
    return {
      success: false,
      message:
        "No response from the server. Please check your internet connection or try again later.",
    };
  } else {
    // Errors unrelated to HTTP responses (e.g., setup, configuration)
    const isTimeoutError = error.code === "ECONNABORTED";
    return {
      success: false,
      message: isTimeoutError
        ? "Request timed out. Please check your connection and try again."
        : error.message || "An unexpected error occurred.",
    };
  }
};

export { globalAxiosCall, handleGlobalError };
