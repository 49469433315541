export const termsOfServiceContent = {
    header: `These Terms and Conditions (”Terms”) govern the use of the platform Outplayed ("Platform"), operated by Fraemerate Private Limited (”Company”).`,
    accountRules: {
      1: `You're responsible for keeping your account secure and for providing accurate information. Falsified details may lead to account suspension.`,
    },
    ageRequirements: {
      1: `Users must be 18 years or older to use the Platform.`,
      2: `Users aged below 18 may only use the Platform under the guidance and consent of a parent/guardian.`,
    },
    streaming: {
      1: `By playing, you agree that your matches may be streamed and stored for marketing or research purposes.`,
    },
    esportsTournaments: {
      1: `Participants must comply with tournament rules.`,
      2: `Violations, including cheating, trolling, or misconduct, may lead to disqualification.`,
    },
    contentOwnership: {
      1: `You own your gameplay content, but you grant us permission to use it in promotional material.`,
    },
    platformUse: {
      1: `Play fair and respect other users. Misuse of the platform may lead to account suspension.`,
    },
    liability: {
      1: `We are not liable for any loss of earnings, payments, or in-game items based on false or inaccurate information you provide.`,
    },
    noGamblingOrBetting: {
      1: `Outplayed is strictly an esports competition platform and does not, in any manner, facilitate, promote, or endorse betting or gambling of any kind. Any form of wagering on esports matches, tournaments, or outcomes through our platform is strictly prohibited. Users found engaging in or promoting betting activities in connection with our platform will face immediate account suspension and potential legal action.`
    },
    payments: {
      1: `Payments for tournament entries and prize distributions are securely processed.`,
      2: `Users must ensure the accuracy of their payment details such as UPI IDs.`,
      3: `Prizes are paid out securely if you win. Ensure your payment details are correct and up-to-date.`
    },
    changes: `Outplayed reserves the right to update these Terms. Continued use of the Platform constitutes acceptance of such changes.`,
  };
  