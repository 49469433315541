export const cancellationAndRefundContent = {
    header: `This Cancellation & Refund Policy, issued by Fraemerate Private Limited ("Company"), the legal
  owner and operator of Outplayed ("Platform") applies to all users, including players, teams, organisers,
  and sponsors engaging with Our Platform.`,
    generalPolicy: {
      1: `Once a transaction is completed on Our Platform, it is considered final, and cancellations are not permitted unless explicitly stated otherwise.`,
      2: `Refunds are processed only under specific conditions outlined below.`,
    },
    forPlayersAndTeams: {
      "No Cancellations": `Once a player or team registers for a tournament, cancellations are not allowed, and no refund will be issued.`,
      "Tournament Cancellation by Platform": `If a tournament is canceled by Outplayed or its organisers, a full refund of the registration fee will be provided.`,
      "Disqualification & No-Shows": `If a player or team is disqualified due to rule violations or fails to appear for a match, no refund will be granted.`,
      "Refund Processing Time": `Approved refunds will be processed within 5-10 business days via the original payment method.`
    },
    forOrganisers: {
      "No Refunds After Payment": `Once an organiser has paid to host a tournament on Outplayed, no refund will be issued if they choose to cancel the event.`,
      "Tournament Cancellation by Platform": `If Outplayed cancels a tournament due to technical, legal, or operational reasons, the organiser will receive a full refund.`,
    },
    exceptionsAndDispute: {
      1: `Any requests for exceptions to this policy must be formally submitted via email at support@outplayed.in with supporting documentation.`,
    },
    changes: `Outplayed reserves the right to update these Terms. Continued use of the Platform constitutes acceptance of such changes.`,
  };
  