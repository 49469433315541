import React from "react";
import "../assets/styles/PrivacyPolicy.css";
import { shippingPolicyContent } from "../constants/shippingPolicyConfig";

export default function ShippingPolicy() {
  return (
    <div>
      <div className="privacy-header">
        <img src="/assets/images/features/OutplayedLogo.svg" />
        <h2>Shipping Policy</h2>
      </div>
      <div className="privacy-shipping">
        <p>
          <strong>
            <i>Last modified: February 19, 2025</i>
          </strong>
        </p>
        <div className="privacy-body-content">
          <p>{shippingPolicyContent.header}</p>
        </div>
        <div className="privacy-copyright">
          <p>2025 &copy; Outplayed.in (owned by Fraemerate Pvt. Ltd.)</p>
        </div>
      </div>
    </div>
  );
}
