import React from "react";
import { privacyPolicyContent } from "../constants/privacyPolicyConfig";
import CorporateVersionPrivacy from "../components/VersionPrivacy/CorporateVersionPrivacy";
import "../assets/styles/PrivacyPolicy.css";
import { cancellationAndRefundContent } from "../constants/cancellationAndRefundConfig";

export default function CancellationAndRefundPolicy() {
  return (
    <div>
      <div className="privacy-header">
        <img src="/assets/images/features/OutplayedLogo.svg" />
        <h2>Cancellation and Refund Policy</h2>
      </div>
      <div className="privacy-body">
        <p>
          <strong>
            <i>Last modified: February 19, 2025</i>
          </strong>
        </p>
        <div className="privacy-body-content">
          <p>
            <strong>{cancellationAndRefundContent.header}</strong>
          </p>
        </div>
        <div className="privacy-body-title">
          <p>General Policy</p>
        </div>
        {Object.entries(cancellationAndRefundContent.generalPolicy).map(
          ([key, value], index) => {
            return (
              <div className="privacy-body-content">
                <p>{value}</p>
              </div>
            );
          }
        )}
        <div className="privacy-body-title">
          <p>For Players & Teams</p>
        </div>
        {Object.entries(cancellationAndRefundContent.forPlayersAndTeams).map(
          ([key, value]) => {
            return (
              <div className="privacy-body-content">
                <p>
                  <strong>
                    <span>{key}</span> :{" "}
                  </strong>
                  {value}
                </p>
              </div>
            );
          }
        )}
        <div className="privacy-body-title">
          <p>For Organisers</p>
        </div>
        {Object.entries(cancellationAndRefundContent.forOrganisers).map(
          ([key, value]) => {
            return (
              <div className="privacy-body-content">
                <p>
                  <strong>
                    <span>{key}</span> :{" "}
                  </strong>
                  {value}
                </p>
              </div>
            );
          }
        )}
        <div className="privacy-body-title">
          <p>Exceptions & Dispute Resolution</p>
        </div>
        {Object.entries(cancellationAndRefundContent.exceptionsAndDispute).map(
          ([key, value]) => {
            return (
              <div className="privacy-body-content">
                <p>{value}</p>
              </div>
            );
          }
        )}
        <div className="privacy-body-title">
          <p>Shipping Policy</p>
        </div>
        <div>
          {Object.keys(privacyPolicyContent.shippingPolicy).map(
            (key, index) => (
              <div key={index} className="term-condition-body">
                <strong>{privacyPolicyContent.shippingPolicy[key]}</strong>
              </div>
            )
          )}
        </div>
        <div className="privacy-copyright">
          <p>2025 &copy; Outplayed.in (owned by Fraemerate Pvt. Ltd.)</p>
        </div>
      </div>
    </div>
  );
}
