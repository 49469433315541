import Carousel from "react-bootstrap/Carousel";
import { useEffect, useRef, useState } from "react";

const videos = [
  require("../../../src/assets/images/fadeValorant.mp4"),
  require("../../../src/assets/images/clove.mp4"),
  require("../../../src/assets/images/valo_earth.mp4"),
  require("../../../src/assets/images/jett.mp4"),
  require("../../../src/assets/images/omen.mp4"),
];

function IndividualIntervalsExample() {
  const [carouselItemCollection, setCarouselItemCollection] = useState([]);
  const videoRefs = useRef([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handler = (nextSlideIndex) => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        if (index === nextSlideIndex) {
          setTimeout(() => video.play(), 100); // Small delay to ensure focus on the current video
        } else {
          video.pause();
          video.currentTime = 0;
        }
      }
    });
  };

  useEffect(() => {
    setCarouselItemCollection(
      videos.map((_, index) => (
        <Carousel.Item key={`carousel-${index}`}>
          <video
            key={`video-${index}`}
            ref={(el) => (videoRefs.current[index] = el)}
            style={{ objectFit: "cover" }}
            preload="auto"
            src={videos[index % videos.length]}
            alt="Example"
            muted
            width={width}
            height={height - 20}
            loop={true}
            onCanPlay={() => {
              if (index === 0) {
                videoRefs.current[0].play();
              }
            }}
          />
        </Carousel.Item>
      ))
    );
  }, [width, height]);

  useEffect(() => {
    if (carouselItemCollection.length) {
      setCarouselItemCollection([...carouselItemCollection]);
    }
  }, [width, height]);

  return (
    <Carousel
      controls={false}
      onSelect={(eventKey) => handler(eventKey)}
      onSlide={(eventKey) => handler(eventKey)}
      fade={true}
      touch={false}
      indicators={false}
      interval={5000}
    >
      {carouselItemCollection}
    </Carousel>
  );
}

export default IndividualIntervalsExample;
