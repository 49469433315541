export const privacyPolicyContent = {
  privacyPolicy: {
    intro:
      "We, at at Fraemerate Private Limited(”Company”), operating under the brand name Outplayed, respect the privacy of Our users and are committed to protecting and safeguarding it.",
    scope:
      "This privacy policy aims to inform you about how We collect, use, communicate, disclose and otherwise make use of your personal information that We receive by your usage of Our platform outplayed.in as well as all the other domains and sub-domains owned and operated by Us and Our mobile application, available at Google play store and Apple app store, (collectively, “Platform”). All Personal Information (as defined below) collected and processed by Us are in accordance with applicable state and central laws of India, including, but not limited to the Information and Technology Act, 2000, Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011, and the Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021 (together, “IT Act”), The Indian Contract Act, 1872, Consumer Protection Act, 2019, and the rules framed thereunder.",
    userConsent:
      "By registering on Our Platform, you agree to provide your express consent to Us to use your Personal Information (in accordance with this Privacy Policy and",
    advisory:
      "You are advised to carefully read this Privacy Policy and the Terms before using the Platform or providing Us with Your Personal Information.",
    representationsByUser: {
      a: "Any and all Personal Information disclosed by you through Our Platform shall be deemed to be disclosed willingly and voluntarily by you.",
      b: "You hereby represent and warrant that either you are of the age of 18 (Eighteen) years or above or you are providing your Personal Information under the supervision of your parent / guardian.",
      c: "All such Personal Information is true and correct to the best of your knowledge.",
      d: "No such Personal Information is in violation of any of your third-party agreement, or of any order/decree/ judgement of any court.",
      e: "We shall not have any liability arising from any misrepresentation, fraud or inauthentic information disclosed by you, nor are We responsible for the verification of any information provided by you.",
    },
    representationsByOutplayed: {
      a: "All information is collected for lawful purposes and in compliance with the laws of India.",
      b: "We shall not retain your Personal Information for longer than the intended time.",
    },
    details: {
      "Data We Collect":
        "We collect your name, email, mobile number, date of birth (DOB), gender, payment info, and gameplay stats (“Personal Information”). We also gather data from streamed matches for research and marketing purposes. We collect certain types of data to ensure platform security, enhance user experience, and analyze engagement. This includes network and security data such as IP addresses, device details, and network activity logs to detect fraudulent activities and prevent unauthorized access. We may also infer approximate geolocation from IP addresses for security, regulatory compliance, and content optimization. Additionally, we track engagement metrics like page views, clicks, and session durations to analyze platform performance and improve user experience.",
      "How We Use It":
        " We use your data to match you with tournaments, process payments, and enhance your experience. Your DOB and gender help us tailor content and tournaments. Your mobile number is only used for contact and is never shared with third parties. Streaming content is used for research, development, and marketing.",
      Payments:
        " We handle payments securely. Your payment details are encrypted and used only for transactions.",
      Streaming:
        " By participating, you agree that your matches may be streamed and used for R&D or marketing.",
      "Your Data, Your Control":
        " You can update or delete your info anytime, except when payment or legal record-keeping requires us to retain data.",
      "Truthful Information":
        " All information you provide (DOB, gender, etc.) is assumed to be true. We are not liable for incorrect information you submit.",
      "Third-Party Stuff":
        " We may link to other services, but your personal info stays with us",
    },
  },
  termsAndConditions: {
    "Account Rules":
      "You're responsible for keeping your account secure and for providing accurate information. Falsified details may lead to account suspension.",
    "Age Requirements":
      "You must be 18 or older to use our platform. If you are younger than 18, you can only use the platform with your parent or guardian's guidance and consent",
    "Esports Tournaments":
      "Follow tournament rules. Cheating, trolling, or other misconduct may result in disqualification.",
    Streaming:
      "By playing, you agree that your matches may be streamed and stored for marketing or research purposes.",
    Payments:
      "Payments for tournament entries or prize payouts are securely processed. Double-check your payment info for accuracy.",
    "Reverse Payments":
      "Prizes are paid out securely if you win. Ensure your payment details are correct and up-to-date.",
    "Content Ownership":
      "You own your gameplay content, but you grant us permission to use it in promotional material.",
    "Platform Use":
      "Play fair and respect other users. Misuse of the platform may lead to account suspension.",
    "No Gambling and Betting Policy":
      "Outplayed is strictly an esports competition platform and does not, in any manner, facilitate, promote, or endorse betting or gambling of any kind. Any form of wagering on esports matches, tournaments, or outcomes through our platform is strictly prohibited. Users found engaging in or promoting betting activities in connection with our platform will face immediate account suspension and potential legal action.",
    "Liability ":
      "We are not liable for any loss of earnings, payments, or in-game items based on false or inaccurate information you provide.",
  },
  cancellationAndRefundPolicyPlayers: {
    "No Cancellations":
      "Once a team or player registers for a tournament, cancellations are not allowed. No refund will be issued under any circumstances.",
    "Tournament Cancellation by Platform":
      "If a tournament is cancelled by the platform or the organisers, all registered teams/players will receive a full refund of their registration fees.",
    "Disqualifications & No-Shows":
      "If a team or player is disqualified due to rule violations or fails to show up for a match, no refund will be granted.",
    "Refund Processing":
      "Eligible refunds will be processed within 5-10 business days through the original payment method.",
  },
  cancellationAndRefundPolicyOrganisers: {
    "No Refunds After Payment":
      " Once an organiser has paid the platform to host a tournament, no refunds will be provided if they choose to cancel the tournament.",
    "Tournament Cancellation by Platform":
      "If the platform cancels a tournament, any payments made by the organiser will be refunded.",
  },
  shippingPolicy: {
    a: "We do not ship or sell physical products at this time.",
    b: "Our platform deals exclusively with digital services, including online tournaments and digital rewards.",
    c: "Any digital purchases, such as tournament registrations or in-game rewards, will be delivered instantly to the registered account."
  },
  policyUpdates: {
    3: "We may update this Privacy Policy and the Terms and Conditions stated herein from time to time. Any such update is immediately effective upon its posting on the Platform, and your continued use of the Platform shall be deemed to be an acceptance of such change.",
  },
  governingLaw: {
    4: "This Privacy Policy shall be governed by and construed in accordance with the laws of Republic of India and the courts at Guwahati, Assam, shall have an exclusive jurisdiction on the matters arising from the Privacy Policy and Terms, without regard to the principles of conflicts of laws.",
  },
  grievances: {
    5: {
      contact:
        "If you have any grievance relating to or arising from this Privacy Policy or the Terms thereof, you may write to Us at support@outplayed.in or contact Our Grievance Officer. The contact information for the Grievance Officer is provided herein in accordance with the IT Act: ",
      fields: {
        Name: "Firoz Anjum Chowdhury",
        // Address: "[---]",
        "E-mail": "support@outplayed.in",
      },
      withdrawal:
        "You may also choose to withdraw your consent provided to Us to use your Personal Information, as detailed in this Privacy Policy, anytime you so desire. We will destroy all such Personal Information shared by you in such a scenario. Any such withdrawal of your consent must be expressed to Us via mail at support@outplayed.in. We try to respond to all legitimate requests within 15 (Fifteen) days. It may take Us more than 15 (Fifteen) days, occasionally, but We will try to resolve/ respond to your grievance at the earliest.",
    },
  },
  disclaimer: {
    6: "We have implemented reasonable security practices and procedures that are commensurate with the industry standards and as provided under the IT Act to protect your Personal Information from unauthorized access, disclosure, loss or misuse. We offer the use of a secure server whenever you access your account information. All the information that is being transmitted to Us by you and / or disclosed by Us to third parties is not completely secure because of the inherent susceptibilities of the internet and reasons beyond our control. By using the Platform, you accept the security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secure, and therefore, there would always remain certain inherent risks regarding use of the Platform. You acknowledge and agree that any information provided by you to Outplayed is transmitted or stored at your sole and own risk.",
  },
};
