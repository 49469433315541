import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Layout from "../Layout/Layout";
import Home from "../../pages/Home";
import Error from "../../pages/Error";
import Login from "../../pages/Login";
import OtpVerification from "../../pages/OtpVerification";
import Register from "../../pages/Register";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import Profile from "../../pages/Profile";
import DeletePage from "../../pages/DeletePage";
import DeletePageTwo from "../../pages/DeletePageTwo";
import { SecureRoute, SecureRegisterRoute } from "../../utils/secureRoute";
import CancellationAndRefundPolicy from "../../pages/CancellationAndRefundPolicy";
import ShippingPolicy from "../../pages/ShippingPolicy";
import TermsAndConditions from "../../pages/TermsAndConditions";
import ContactUs from "../../pages/ContactUs";

export default function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="profile"
            element={
              <SecureRoute>
                <Profile />
              </SecureRoute>
            }
          />
        </Route>
        <Route path="/login-register" element={<Login />} />
        <Route path="/otp-verification" element={<OtpVerification />} />
        <Route
          path="/register"
          element={
            <SecureRegisterRoute>
              <Register />
            </SecureRegisterRoute>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cancellation-and-refund" element={<CancellationAndRefundPolicy/>} />
        <Route path="/contact-us" element={<ContactUs/>} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
        <Route path="/shipping-policy" element={<ShippingPolicy/>} />
        <Route path="/delete-page-one" element={<DeletePage />} />
        <Route
          path="/delete-page-two"
          element={
            <SecureRoute>
              <DeletePageTwo />
            </SecureRoute>
          }
        />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}
