import React from "react";
import "../assets/styles/Profile.css";
import DeleteCenteredModal from "../components/Modals/DeleteCenteredModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { persistor } from "../store/store";
import { deleteToken } from "../utils/tokenfunction";

export default function Profile() {
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleLogout = () => {
    deleteToken("AccessToken");
    deleteToken("RefreshToken");
    persistor.purge();
    window.location.href = "/";
  };
  return (
    <div className="profile-container">
      <div className="profile-row-image-section">
        <div className="profile-row-image-and-section">
          <img
            src={
              userData?.uploadedImage
                ? userData?.ProfilePic
                : "/assets/images/features/defaultUser.webp"
            }
            style={{ height: "100px", aspectRatio: 1, borderRadius: "100px" }}
          />
          <p>{userData?.Username}</p>
        </div>
        <div style={{ display: "flex", gap: "2rem" }}>
          <button style={{ color: "#02061A"}} onClick={handleLogout}>Logout</button>
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#centerModal" /* this id should match the id of the modal */
          >
            {" "}
            <img
              src="/assets/images/features/DeleteIcon.svg"
              style={{
                height: "25px",
                width: "25px",
                marginRight: "4px",
                marginBottom: "2px",
              }}
            />{" "}
            Delete Account
          </button>
        </div>
      </div>
      <div className="profile-row">
        <div className="profile-row-text-input">
          <p>Full Name</p>
          <input
            placeholder="Your First Name"
            value={userData?.Name || userData?.Username}
            readOnly
          />
        </div>
        <div className="profile-row-text-input">
          <p>Mobile number</p>
          <input
            placeholder="Mobile Number"
            value={userData?.Contact}
            readOnly
          />
        </div>
      </div>
      <div className="profile-row">
        <div className="profile-row-text-input">
          <p>Gender</p>
          <input placeholder="Gender" value={userData?.Gender} readOnly />
        </div>
        <div className="profile-row-text-input">
          <p>Country</p>
          <input
            placeholder="Country Name"
            value={userData?.Country}
            readOnly
          />
        </div>
      </div>
      <div className="profile-row">
        <div className="profile-row-text-input">
          <p>DOB</p>
          <input
            placeholder="Date of Birth"
            value={moment(userData?.DOB, "YYYY-MM-DD").format("DD MMMM YYYY")}
            readOnly
          />
        </div>
        <div className="profile-row-text-input">
          <p>Region</p>
          <input placeholder="Region" value={userData?.Region} readOnly />
        </div>
      </div>
      <DeleteCenteredModal
        id={"centerModal"}
        title="Delete Account?"
        body={
          " Are you sure you want to delete your account? This action is irreversible, and all your data will be permanently deleted"
        }
        onClick={() => navigate("/delete-page-one")}
      />
    </div>
  );
}
