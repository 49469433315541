import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "../../assets/styles/components/navbar/Navbar.css";
import OutplayedIcon from "../../assets/images/icons/op_yellow.png";
import OutplayedIconMobile from "../../assets/images/navbarMobile/outplayedIcon.svg";
import HamburgerIcon from "../../assets/images/navbarMobile/hamburgerIcon.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProfilePictureDownload } from "../../functionality/ProfilePicture.functionality";
import { checkLogin, updateUserData } from "../../store/slice/userSlice";
import { persistor } from "../../store/store";
import { toast } from "react-toastify";

export default function Navbar() {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.user);
  const { userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const toggleMenuVisibility = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const fetchProfilePic = async () => {
      if (isLoggedIn && userData?.UUID) {
        try {
          const profilePic = await ProfilePictureDownload(userData?.UUID);
          dispatch(updateUserData({ ProfilePic: profilePic }));
        } catch (error) {
          toast.error("Error in fetching profile picture", {
            theme: "dark",
            autoClose: 1300,
            style: { fontSize: "1rem" },
          });
        }
      }
    };

    fetchProfilePic();
  }, []);
  return (
    <>
      <header>
        {/* Mobile Navbar */}
        <nav
          className={`navbar_mobile`}
          style={isMenuVisible ? { backgroundColor: "#02061A" } : {}}
        >
          <Link to="/">
            <img src={OutplayedIconMobile} alt="Outplayed icon" />
          </Link>
          <button
            className="navbar_mobile__hamburger"
            onClick={toggleMenuVisibility}
          >
            <img src={HamburgerIcon} alt="Hamburger icon" />
          </button>
          {isMenuVisible && (
            <div className="navbar_mobile__menu">
              <HashLink to="/#contact_us" onClick={toggleMenuVisibility}>
                Contact Us
              </HashLink>
              <HashLink to="/#features-section" onClick={toggleMenuVisibility}>
                Services
              </HashLink>
              <HashLink to="/#games-section" onClick={toggleMenuVisibility}>
                Games
              </HashLink>
              <HashLink to="/#events-section" onClick={toggleMenuVisibility}>
                Events
              </HashLink>
              <HashLink
                to="/#words-from-crew-section"
                onClick={toggleMenuVisibility}
              >
                Crew
              </HashLink>
              <HashLink to="/#join-us-section" onClick={toggleMenuVisibility}>
                Beta Registration
              </HashLink>
              {isLoggedIn ? (
                <Link to="/profile" onClick={toggleMenuVisibility}>
                  <div>
                    <div></div>
                    <div>{userData.Name}</div>
                  </div>
                </Link>
              ) : (
                <Link to="/login-register" onClick={toggleMenuVisibility}>
                  Login
                </Link>
              )}
            </div>
          )}
        </nav>

        {/* Desktop Navbar */}
        <nav className="navbar_desktop">
          <section className="navbar-items-container">
            <div
              style={{
                backgroundImage: `url(${OutplayedIcon})`,
                backgroundSize: "8rem",
                height: "100%",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <Link to="/contact-us">Contact Us</Link>
            <HashLink to="/#features-section">Services</HashLink>
            <HashLink to="/#games-section">Games</HashLink>
            <HashLink to="/#events-section">Events</HashLink>
            <HashLink to="/#words-from-crew-section">Crew</HashLink>
            <HashLink to="/#join-us-section" id="nav-cta">
              Register For Beta
            </HashLink>
            <Link to="/privacy-policy">Privacy Policy</Link>
            {isLoggedIn ? (
              <Link to="profile">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <div
                    style={{
                      width: "30px",
                      aspectRatio: 1,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      borderRadius: "50%",
                      backgroundImage: userData?.uploadedImage
                        ? `url(${userData.ProfilePic})`
                        : "/assets/images/features/defaultUser.webp",
                    }}
                  ></div>
                  <div style={{ fontWeight: "bold" }}>{userData.Username}</div>
                </div>
              </Link>
            ) : (
              <Link
                to="/login-register"
                style={{ textDecoration: "none", width: "10rem" }}
              >
                <button
                  className="login-register-button"
                  style={{ width: "100%" }}
                >
                  LOG IN
                </button>
              </Link>
            )}
          </section>
        </nav>
      </header>
    </>
  );
}
